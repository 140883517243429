<template>
    <video ref="videoPlayer" class="video-js" :src="src"></video>
</template>

<script>
const videojs = () => import('video.js');

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {
          controls: true,
          bigPlayButton: false,
          autoplay: true,
        };
      },
    },
    src: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    videojs().then((videojsModule) => {
      const video = videojsModule.default;
      this.player = video(this.$refs.videoPlayer, this.options, () => {
      });
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

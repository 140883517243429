<template>
  <div class="upload-input">
    <div class="inner-input radius dropbox" v-if="!file">
      <input
        type="file"
        name="fileinput"
        id="fileinput"
        if="file"
        ref="myFile"
        class="inputfile"
        @change="updatePreview"
      />

      <label v-show="!file" class="upload-title" >
        <img :src="require('@/assets/pictos/picto_plus_upload.svg')">
        Click or drag to upload a file
      </label>
    </div>
    <div class="preview-file" v-if="file && !preventButton">
      <div class="top-part">
        <file-preview
          class="preview"
          :filePreview="filePreview"
          :onlyPreview="true"
          :fileType="fileType"
          v-if="this.file  && !this.onlyIcon"
        />
        <img
          :src="require('@/assets/pictos/picto_file_purple.svg')"
          class="preview"
          v-else-if="this.file"
        />
      </div>
      <div class="bot-part">
        <div class="left-part">
          <div class="name-file">
            Name : <br>
            <span>{{file.name}}</span>
          </div>
          <div class="type-file">
            Type : <br>
            <span>{{fileType}}</span>
          </div>
        </div>
        <div class="right-part">
          <div
            class="btn-green reverse radius"
            @click.prevent="cancel"
          >
            Cancel
          </div>
          <div
            class="btn-green radius"
            @click.prevent="uploadMedia"
          >
            Upload
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilePreview from '@/components/utilityPartials/_filePreview.vue';

export default {
  name: 'ImageFileUpload',
  components: {
    FilePreview,
  },
  props: {
    value: {
      type: String,
    },
    preventButton: {
      type: Boolean,
      default: true,
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: false,
      filePreview: false,
      fileUploadPath: '',
      fileType: false,
      data: '',
      name: 'my_record',
      type: '',
      uploading: false,
      media: 'file',
      readyToUpload: true,
    };
  },

  methods: {
    cancel() {
      this.file = false;
      this.filePreview = false;
      this.fileUploadPath = '';
      this.fileType = false;
    },
    uploadMedia() {
      if (this.readyToUpload) {
        this.uploading = true;
        this.upload().then((endpoint) => {
          this.$store.dispatch('user/uploadMedia', {
            data: endpoint,
            name: this.file.name.split('.').slice(0, -1).join('.'),
            type: this.fileType,
          }).then(() => {
            this.cancel();
          });
        });
      }
      return false;
    },
    upload() {
      return new Promise((resolve) => {
        const { file } = this;
        if (file) {
          this.$api.post('getSignedUrl',
            { filename: file.name, filetype: file.type }).then((res) => {
            this.fileUploadPath = res.data.link;
            const fileEndpoint = res.data.s3Endpoint;
            this.$api.raw('PUT', this.fileUploadPath, file).then(() => {
              this.$emit(
                'input',
                fileEndpoint,
              );
              resolve(fileEndpoint);
            });
          });
        } else {
          resolve(false);
        }
      });
    },
    updatePreview(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file = event.target.files[0];

      let blob = '';
      const URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        blob = URL.createObjectURL(this.file);
      }
      this.filePreview = blob;
      // eslint-disable-next-line prefer-destructuring
      this.fileType = this.file.type.split('/')[0];
      this.name = this.file.name.split('.').slice(0, -1).join('.');
      try {
        if (this.file.type.split('/')[0] === 'image') {
          this.fileType = 'Image';
        } else if (this.file.type.split('/')[0] === 'video') {
          this.fileType = 'Video';
        } else if (this.file.type === 'application/pdf') {
          this.fileType = 'Pdf';
        } else if (this.file.type === 'application/zip') {
          this.fileType = 'Zip';
        }
      } catch (ee) {
        this.fileType = 'Image';
      }
    },
  },

  watch: {
    file(newFile) {
      this.$emit('fileUpdate', newFile);
    },
  },

};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.upload-input{
  height: calc(100% - 4vh);
  width: calc(100% - 4vh);
  position: absolute;
  top: 2vh;
  left: 2vh;
  &:hover{
    .inner-input{
      background-color: rgba(140, 40, 225, 0.2);
    }
  }
  .inner-input{
    width: calc(100% - 0.4vh);
    height: calc(100% - 0.4vh);
    background-color: #ffffff;
    border: 0.2vh dashed $logo_color_dark;
    color: $logo_color_dark;

    align-items: center;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    position: relative;
    height: 100%;
    width: calc(100% - 0.4vh);
    margin: 0 auto;

    transition: all .2s;
    .inputfile {
      padding: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      left:0;
      top: 0;
      opacity: 0;
      z-index: 1;
      cursor: pointer;
    };
    .upload-title{
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      text-align: center;
      img{
        width: 6vh;
        margin-bottom: 2vh;
      }
    }
    .preview{
      font-size: 0;
      height: 450px;
      width:auto;
      > div{
        img, embed{
          padding: 10px;
          height: calc(450px - 20px);
        }
      }
    }
  }
}

.preview-file{
  display: flex;
  height: 100%;
  flex-direction: column;
  .top-part{
    height: 78%;
  }
  .bot-part{
    height: calc(22% - 2vh);
    padding-top: 2vh;
    display: flex;
    justify-content: space-between;
    .left-part{
      font-size: $fnt-14;
      span{
        font-family: 'MontserratBold';
      }
      .name-file{
        margin-bottom: 2vh;
      }
    }
    .right-part{
      .btn-green{
        margin-bottom: 1vh;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
}

</style>

<template>
  <div class="component-fullwidth">
    <div class="flex-container flex-2-tier-sdlarge no-padding no-margin">
      <div class="elem-flex">
        <div class="title-page">
          Media Library
        </div>
      </div>
      <div class="elem-flex">
      </div>
    </div>
    <div class="inner-container">
      <div class="flex-container flex-2-tier-sdlarge reverse">
        <div class="elem-flex mhspe flex-overflow-column radius">
          <div class="selecteur align-left">
            <div
              class="elem-select selection"
              @click="upload"
            >
              Add Media
            </div>
          </div>
          <div class="container-upload">
            <div class="container-upload-file radius box-shadow">
              <image-upload
                class="upload"
                v-model="dataFile"
                :preventButton="false"
                ref="file"
                @fileUpdate="fileUpdateHandler"
              />
            </div>
            <div class="container-upload-audio radius"
              @click="() => uploadMedia('audio')">
              <div class="inner-rec">
                <img :src="require('@/assets/pictos/picto_rec_audio.svg')">
                <div class="label">
                  Record New Audio
                </div>
              </div>
            </div>
            <div class="container-upload-video radius"
              @click="() => uploadMedia('video')">
              <div class="inner-rec">
                <img :src="require('@/assets/pictos/picto_rec_video.svg')">
                <div class="label">
                  Record New Video
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="elem-flex mhspe flex-overflow-column radius">
          <div class="selecteur align-left">
            <div
              class="elem-select"
              :class="select_right === 'all' ? 'selection' : ''"
              @click="select_right = 'all'; selectParam('All'); pslistBGA.update();"
            >
              All Media
            </div>
            <div
              class="elem-select"
              :class="select_right === 'images' ? 'selection' : ''"
              @click="select_right = 'images'; selectParam('Image'); pslistBGA.update();"
            >
              Images
            </div>
            <div
              class="elem-select"
              :class="select_right === 'videos' ? 'selection' : ''"
              @click="select_right = 'videos'; selectParam('Video'); pslistBGA.update();"
            >
              Videos
            </div>
            <div
              class="elem-select"
              :class="select_right === 'pdfs' ? 'selection' : ''"
              @click="select_right = 'pdfs'; selectParam('Pdf'); pslistBGA.update();"
            >
              PDF
            </div>
            <div
              class="elem-select"
              :class="select_right === 'audio' ? 'selection' : ''"
              @click="select_right = 'audio'; selectParam('Audio'); pslistBGA.update();"
            >
              Audio
            </div>
            <div
              class="elem-select"
              :class="select_right === 'other' ? 'selection' : ''"
              @click="select_right = 'other'; selectParam('Other'); pslistBGA.update();"
            >
              Other
            </div>
          </div>
          <div class="sub-selecteur align-right" v-if="select_right === 'all'">
            <div
              class="elem-sub-select no-select"
            >
              Sort by :
            </div>
            <div
              class="elem-sub-select"
              :class="sort === 'date' ? 'selection' : ''"
              @click="sort = 'date'; selectParam('All'); pslistBGA.update();"
            >
              Date
            </div>
            <div
              class="elem-sub-select"
              :class="sort === 'type' ? 'selection' : ''"
              @click="sort = 'type'; pslistBGA.update();"
            >
              Type
            </div>
          </div>
          <div class="container-selected">
          </div>
          <div class="searchView" id="listmedia">
            <div class="results" v-if="mediaLibrary[0].media.length > 0">
              <div class="mediaSection"
                v-for="section in mediaLibrary"
                :key="section.name"
              >
                <div class="title-type"
                  v-if="section.media.length !== 0 &&
                  section.name !== 'Date' &&
                  section.name !== 'Default'"
                >
                  {{section.name}}
                </div>
                <div class="cont-media">
                  <div
                    v-for="media in section.media"
                    :key="media._id"
                    class="media"
                    v-tooltip.top-center="{
                      content: media.name,
                      offset: -100,
                      delay: {
                        show: 1000,
                        hide: 0,
                      }
                    }"
                    @click="() => openView(media, media.type)"
                  >
                    <file-preview
                      class="elem-media"
                      :filePreview="media.data"
                      :fileType="media.type"
                    />
                    <label class="label-media">
                      {{media.name}}.{{media.data.split('.')[media.data.split('.').length - 1]}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="results" v-else>
              <div>No media</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PerfectScrollbar from 'perfect-scrollbar';
import MediaUpload from '@/components/UniversalComponents/Media/mediaUpload.vue';
import FilePreview from '@/components/utilityPartials/_filePreview.vue';
import MediaView from '@/components/UniversalComponents/Media/mediaView.vue';
import MediaMixin from '@/modules/mixins/selectMedia';
import ImageUpload from '@/components/UniversalComponents/Media/imageFile.vue';

export default {
  name: 'MediaLibrary',
  components: {
    FilePreview,
    ImageUpload,
  },
  mixins: [MediaMixin],
  data() {
    return {
      dataFile: '',
      nameFile: 'my_record',
      typeFile: '',
      uploadingFile: false,
      readyToUploadFile: false,
      select_right: 'all',
      pslistBGA: '',
      sort: 'date',
      params: [],
      sortpossibilities: ['date', 'type'],
      paramList: ['All', 'Image', 'Video', 'Pdf', 'Audio', 'Other'],
    };
  },
  computed: {
    ...mapState('user', ['userInfo']),
    mediaLibrary() {
      return this.$store.getters['user/media'](this.params, this.sort);
    },
  },
  mounted() {
    this.$store.dispatch('user/getUserInfo');
    this.pslistBGA = new PerfectScrollbar('#listmedia', {
      wheelPropagation: false,
    });
    this.pslistBGA.update();
  },
  methods: {
    fileUpdateHandler(file) {
      this.nameFile = file.name.split('.').slice(0, -1).join('.');
      if (file.type.split('/')[0] === 'image') {
        this.typeFile = 'Image';
      } else if (file.type.split('/')[0] === 'video') {
        this.typeFile = 'Video';
      } else if (file.type === 'application/pdf') {
        this.typeFile = 'Pdf';
      } else if (file.type === 'application/zip') {
        this.typeFile = 'Zip';
      }
      this.readyToUploadFile = true;
    },
    selectParam(param) {
      if (param === 'All') {
        this.params = [];
      } else {
        this.params = [param];
        this.sort = 'date';
      }
    },
    toggleSort() {
      if (this.sort === 'date') {
        this.sort = 'type';
      } else {
        this.sort = 'date';
      }
    },
    upload() {
      this.$modal.show(MediaUpload, {}, {
        name: 'MediaUpload',
        width: '50%',
        height: '10vh',
        classes: ['overflow'],
      });
    },
    openView(media, type) {
      if (type === 'Audio') {
        this.$modal.show(MediaView, { media }, {
          name: 'MediaView',
          width: '50%',
          height: '20vh',
          adaptive: true,
          classes: ['overflow'],
        });
      } else if (type === 'Video') {
        this.$modal.show(MediaView, { media }, {
          name: 'MediaView',
          width: '50%',
          height: '20vh',
          adaptive: true,
          classes: ['overflow'],
        });
      } else {
        this.$modal.show(MediaView, { media }, {
          name: 'MediaView',
          width: '60%',
          height: '70vh',
          adaptive: true,
          classes: ['overflow'],
        });
      }
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  .container-upload{
    width: 100%;
    height: 100%;
    min-height: 73.5vh;
    // background-color: red;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .container-upload-file{
      position: relative;
      width: 100%;
      height: 75%;
      min-height: 50vh;
      margin-bottom: 2vh;
    }
    .container-upload-audio{
      height: 19.6vh;
      position: relative;
      width: calc(calc(calc(100% - 6vh) / 2));
      margin-right: 1.5vh;
      // height: 25%;
      border: 0.1vh solid $logo_color;
      padding: 1vh;
      cursor: pointer;
      &:hover{
        .inner-rec{
          background-color: rgba(140, 40, 225, 0.2);
        }
      }
    }
    .container-upload-video{
      height: 19.6vh;
      position: relative;
      width: calc(calc(calc(100% - 6vh) / 2));
      // height: 25%;
      border: 0.1vh solid $logo_color;
      padding: 1vh;
      cursor: pointer;
      &:hover{
        .inner-rec{
          background-color: rgba(140, 40, 225, 0.2);
        }
      }
    }
    .inner-rec{
      position: relative;
      width: calc(100% - .2vh);
      height: calc(100% - .2vh);
      border-radius: 0.5vh;
      border: 0.1vh solid rgba(81, 20, 138, 0.5);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      transition: all .2s;
      img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 6vh;
      }
      .label{
        margin-bottom: 1.5vh;
        font-weight: 600;
      }
    }
  }

  .searchView{
    width: 100%;
  }
  .results{
    width: 100%;
  }
  .selecteur{
    width: 95%;
    margin-bottom: 1.5vh;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    .elem-select{
      // font-size: 1.4vh;
      margin-right: 0 !important;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  .sub-selecteur{
    .elem-sub-select{
      &:last-child{
        margin-right: 2vh !important;
      }
    }
  }
  .params{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    .param{
      width: calc(50% - 0.5vh);
      margin-right: 1vh;
      margin-bottom: 1vh;
      cursor: pointer;
      transition: all .2s;
      &:first-child{
        width: 100%;
        margin-right: 0;
      }
      &:nth-child(2n+1){
        margin-right: 0;
      }
      &:hover{
        opacity: 0.8;
      }
      > div{
        min-height: 10vh;
        background-color: $fond_gray;
        color: #ffffff;
        text-align: center;
        .picto{
          width: 10vh;
          font-size: 0;
        }
        .label{
          width: 100%;
          text-align: center;
          padding-bottom: 1vh;
          font-size: $classic_min_fs;
        }
        &.current{
          background-color: $logo_color_dark;
        }
      }
    }
  }
  .head-result{
    width: 100%;
    margin-bottom: 2vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .btn-green{
      margin: 0;
    }
    .sort{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      label{
        margin-right: 0.5vh;
      }
      .v-select{
        width: 10vh;
        background-color: #ffffff;
        .vs__clear{
          display: none !important;
        }
      }
    }
  }
  .mediaSection{
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
    flex-wrap: wrap;
    .title-type{
      margin-bottom: 1vh;
    }
    &:first-child{
      margin-top: 0;
    }
    .cont-media{
      display:flex;
      flex-direction: row;
      width: calc(100% - 1vh);
      padding-right: 1vh;
      .media{
        border-radius: 0.5vh;
        height: 20vh;
        overflow: hidden;
        margin-right: 2vh;
        margin-bottom: 2vh;
        position: relative;
        cursor: pointer;
        &:nth-child(4n){
          margin-right: 0;
          width: calc(calc(calc(100% - 2vh) / 3) * 1 );
        }
        &:nth-child(4n+1){
          width: calc(calc(calc(100% - 2vh) / 3) * 1 );
          video{
            height: 100%;
            width: auto;
          }
        }
        &:nth-child(4n+2){
          margin-right: 0;
          width: calc(calc(calc(100% - 2vh) / 3) * 2 );
        }
        &:nth-child(4n+3){
          width: calc(calc(calc(100% - 2vh) / 3) * 2 );
        }
        .label-media{
          font-size: $micro_fs;
          text-overflow: ellipsis;
          overflow: hidden;
          position: absolute;
          bottom: 0;
          left: 0;
          width: calc(100% - 2vh);
          padding: 1vh;
          color: #ffffff;
          text-align: left;
          text-shadow: 0 0 0.4vh rgba(22, 0, 51, 1);
        }
      }
    }
  }

  .searchView{
    position: relative;
    overflow: hidden;
  }
</style>

<template>
  <div class="display">
    <div class="green-circle-button close-button" @click="closeModal">
      <span>X</span>
    </div>
    <div class="subtitle-page flex-row contentbox blox-shadow radius">
      <div class="title" v-if="!editing">
        <div class="subtitle-page">
          {{media.name}}.{{media.data.split('.')[media.data.split('.').length - 1]}}
        </div>
        <div class="updatedate">
          Updated at : {{media.updatedAt | moment("DD MMMM YYYY")}}
        </div>
      </div>

      <div class="edit_name" v-if="editable && editing">
        <input class="v-select" v-model="tempName" />
        <div class="options">
          <div class="btn-green small reverse radius" @click="toggleEdit"  >
            <span>Cancel</span>
          </div>
          <div class="btn-green small radius" @click="submitChange"  >
            <span>Update</span>
          </div>
        </div>
      </div>
      <div class="options" v-if="editable && !editing" >
        <div
          :download="media.data"
          target="_blank"
          class="btn btn-green radius"
          @click="dl"
        >
          Download
        </div>
        <div
          class="name-button btn btn-green radius"
          @click="toggleEdit" v-if="editable && !editing"
        >
          Edit
        </div>
        <div class="btn-green reverse radius" @click="deleteMedia">Delete</div>

      </div>
    </div>
    <!-- {{media}} -->
    <div class="contentmedia contentbox box-shadow radius">
      <img  class="doc boxshadow-middle" v-if="media.type === 'Image'" :src="media.data" />

      <video-player class="doc"
        v-else-if="media.type === 'Video'" :src="media.data" />
      <embed class="doc embed boxshadow-middle"
        v-else-if="media.type === 'Pdf'"
        :src="media.data"
      />
      <audio-player class="doc audio"
        v-else-if="media.type === 'Audio'"
        :src="media.data"
      />
      <div  v-else>
        <p> Can't display media</p>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from '@/components/utilityPartials/_videoPlayer.vue';
import download from '@/modules/mixins/downloadMedia';

export default {
  name: 'MediaView',
  props: ['media'],
  mixins: [download],
  data() {
    return {
      editable: false,
      editing: false,
      tempName: '',
    };
  },
  components: {
    VideoPlayer,
  },
  mounted() {
    this.editable = this.$store.getters['user/ownsMedia'](this.media._id);
  },
  methods: {
    toggleEdit() {
      this.editing = !this.editing;
      this.tempName = this.media.name;
    },
    submitChange() {
      this.editing = false;
      this.media.name = this.tempName;
      this.$store.dispatch('user/updateMedia', { media: { name: this.tempName }, mediaId: this.media._id });
    },
    deleteMedia() {
      this.$store.dispatch('user/deleteMedia', this.media._id).then(() => {
        this.$modal.hide('MediaView');
      });
    },
    dl() {
      this.downloadMedia(this.media, 'data');
    },
    closeModal() {
      this.$modal.hide(this.modalName ? this.modalName : 'MediaView');
    },

  },

};
</script>

<style lang="scss">
 @import "@/assets/scss/_variables.scss";
 img{
   width: 100%;
 }
 .edit_name{
   width: 100%;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   .options{
     max-width: 8vw !important;
     margin-left: 2vh;
   }
 }
.display{
  height: calc(100% - 2vh);
  width: calc(100% - 2vh);
  text-align: center;
  position: relative;
  padding: 1vh;
  background-color: #f8f8f8;
  .title{
    width: calc(100% - 8vw);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .subtitle-page{
      text-align: left;
      margin-bottom: 0;
    }
    .updatedate{
      margin-top: 0.5vh;
      margin-bottom: 0.5vh;
      font-size: $fnt-12;
    }
  }
  h2{
    text-align: left;
    margin: 0;
    position: relative;
    padding-right: 15vh;
    .options{
      position: absolute;
      top: 0;
      right: 0;
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .btn{
        margin: 0;
        margin-right: 1vh;
        &:last-child{
          margin: 0;
        }
      }
    }
  }

  .contentmedia{
    overflow: hidden;
    margin-top: 2vh;
    // width: calc(100% - 2vh);
    // padding: 1vh;
  }
  .doc{
    // height: 80%;
    width: auto;
    vertical-align: middle;
    &.audio{
      margin: 0 auto;
      margin-top: 1vh;
      height: 80%;
      width: 60%;
      vertical-align: middle;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    &.embed{
      width: 100%;
      height: 400px;
    }
  }
}
</style>
